import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildVersionService {

  private _hasUpdate = new BehaviorSubject<boolean>(false);
  private _backendServer = new BehaviorSubject<string>('Unknown');

  get hasUpdate$() {
    return this._hasUpdate.asObservable();
  }

  get backendServer$() {
    return this._backendServer.asObservable();
  }

  updateHasUpdate(value: boolean) {
    this._hasUpdate.next(value);
  }

  updateBackendServer(value: string) {
    this._backendServer.next(value);
  }

  removeCacheReload(url: string) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Pragma', 'no-cache');
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // window.location.reload();
        window.location.href = window.location.href;
      }
    };
    xhr.send();
  }
  constructor() { }
}
