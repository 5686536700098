<app-account></app-account>

<div class="phone-verification">
    <div class="sendCode">
        <h3 class="pb-5 headingh2">Verify your mobile number</h3>

        <div class="getCode" *ngIf="!showcodeBox">
            <form [formGroup]="updatemobileForm" (ngSubmit)="submit()">
                <button *ngIf="showInput" class="primary-btn mt-5" type="submit" style="margin: auto;">Send
                    Code</button>
                <button *ngIf="!showInput" class="primary-btn mt-5" type="button" style="margin: auto;" (click)="sendCode()">Send Code</button>
                <div class="iagree mt-5">
                    <div class="form-group">
                        <label>
                            <input type="checkbox" (change)="showPhoneField($event)">
                            Change mobile number
                        </label>
                    </div>
                </div>

                <div class="mobileno-update mt-5 " *ngIf="showInput">

                    <label class="floating-label">Mobile Number *</label>
                    <div class="form-group bottomLineInput">

                        <div class="phone-numberselection">
                            <select formControlName="operator">
                                <option selected value="">Operator</option>
                                <option *ngFor="let list of metaData?.mobileNetwork" [value]="list.lookupId">
                                    {{list.value}}
                                </option>
                            </select>
                            <input formControlName="phoneNumber" placeholder="03xx-xxxxxxx" type="text" class="form-control Phone-number-input">
                        </div>
                    </div>
                </div>
            </form>
        </div>



        <div class="code-box" style="text-align: left;" *ngIf="showcodeBox">
            <form [formGroup]="pinCodeForm" (ngSubmit)="verifyPinCode()">

                <div class="form-group bottomLineInput">
                    <label class="floating-label">Enter Code</label>
                    <input formControlName="userPin" type="text" class="form-control" />

                </div>
                <div class="d-flex justify-content-center">
                    <button class="primary-btn mt-3 d-table mr-2" type="button" (click)="showcodeBox = !showcodeBox">Back</button>

                    <button class="primary-btn mt-3 d-table" type="submit">Verify</button>

                </div>
                 </form>
        </div>
    </div>

</div>

<ng-template #verifyPhone>
    <div class="modal-main">
        <div class="modal-head">
            <h3 i18n>Mobile number verification</h3>
        </div>
        <div class="modalbody">
            <p i18n>A verification code is sent to your registered mobile number. You can update the mobile number before this verification</p>

            <div class="modal-btns">
                <button class="primary-btn " i18n>Update Number</button>
                <button class="primary-btn " i18n>Send Code</button>
                <button class="primary-btn " i18n>Cancel</button>
            </div>
        </div>
    </div>
</ng-template>
