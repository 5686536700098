import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remainChars'
})
export class RemainCharsPipe implements PipeTransform {

  transform(value: number, limit1: number, limit2: number) {
      
  }

}
