import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {
api = environment.apiEndPoint;

  constructor(private http:HttpClient) { }

  getCityProvinceMetaData(){
    return this.http.get( this.api+'/RegCustomer');
  }

  getCustomerMetaData(){
    return this.http.get( this.api+'/Customer/GetCustomerSD');
  }

  register(data){
      return this.http.post(this.api+'/RegCustomer', data);
  }

  login(userName, password){
      return this.http.post(this.api+'/Auth/Login', {userName, password});
  }

  sendVerificationCode(data){
      return this.http.put(this.api+'/User/GeneratePin', data);
  }

  verifyPinCode(userPin){
    return this.http.put(this.api+ '/User/VerifyPin', userPin);
  }

  resetPasswordVerifyPhone(data){
    return this.http.put(this.api+'/Auth/ResetPassword', data);
  }

  setPassword(data){
    return this.http.put(this.api+'/Auth/UpdatePassword', data);
  }

  submitProfile(data){
    return this.http.put(this.api+'/User/UpdateStatus', data);
  }

  
}
